import React from 'react';
import PropTypes from 'prop-types';
import { StyledResidenceParam } from './styled';
import { FlexCenterAligned } from '../../../styled/common';

export const ResidenceParam = ({ isMobile, param, icon }) => (
  <StyledResidenceParam tag={isMobile ? 'body_2' : null}>
    <FlexCenterAligned>{icon}</FlexCenterAligned>
    <span>{param}</span>
  </StyledResidenceParam>
);

ResidenceParam.propTypes = {
  isMobile: PropTypes.bool,
  param: PropTypes.string,
  icon: PropTypes.element
};
