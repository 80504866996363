import styled from 'styled-components';
import Link from 'next/link';
import { CardPreview, itemCardStyles } from '../../ui/ItemCard/styled';
import LazyImage from '../../HOC/lazyImage';
import { Typography } from '../../ui/Typography';
import { ellipsisText, FlexColumn } from '../../../styled/common';

export const ResidenceCardStyled = styled(Link)`
  ${itemCardStyles};
  margin-bottom: 0;
`;

export const CardPreviewImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
`;

export const ResidenceTitle = styled(Typography)`
  overflow: hidden;
  margin: 5px 0 0;
`;

export const ResidencePrice = styled(Typography)`
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;

  span:last-child {
    margin-left: 2px;
  }
`;

export const ResidenceCardPreview = styled(CardPreview)`
  @media (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-top: 71%;
  }
`;

export const ResidenceContent = styled(FlexColumn)`
  padding: 5px 0;

  & > *:not(:first-child) {
    margin-top: 5px;
  }
`;

export const StyledResidenceParam = styled(Typography)`
  display: flex;
  align-items: center;

  & > div {
    justify-content: flex-start;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  & > span {
    ${ellipsisText}
  }
`;
