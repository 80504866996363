import React from 'react';
import Link from 'next/link';
import {
  HeaderRightBlock,
  Headline,
  SectionHeaderContainer,
  StyledLink
} from './styled';
import { NewSectionHeaderProps } from './types';

export const NewSectionHeader = ({
  title,
  url,
  urlTitle,
  rightBlock,
  headlineType = 'h4',
  asLink,
  cypress,
  showBothRightBlocks = false
}: NewSectionHeaderProps) => {
  const SectionLink = url && (
    <Link as={asLink} href={url} target="_self" data-cy="show-all-link">
      <StyledLink tag="body_1">{urlTitle}</StyledLink>
    </Link>
  );

  return (
    <SectionHeaderContainer>
      <Headline tag={headlineType} data-cy={cypress}>
        {title}
      </Headline>
      <HeaderRightBlock>
        {showBothRightBlocks ? (
          <>
            {SectionLink}
            {rightBlock}
          </>
        ) : (
          rightBlock || SectionLink
        )}
      </HeaderRightBlock>
    </SectionHeaderContainer>
  );
};
