import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Banner = styled.div`
  display: none;
  width: 220px;
  min-height: 100vh;

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    display: block;
  }
`;

export const MainContent = styled.main`
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
