import React from 'react';
import { CardsContainer } from './styled';
import { CardsLayoutProps } from './types';

export const CardsLayout = ({
  children,
  hasHorizontalScroll = false,
  forwardedRef
}: CardsLayoutProps) => {
  const refList = forwardedRef || null;
  return (
    <CardsContainer ref={refList} hasHorizontalScroll={hasHorizontalScroll}>
      {children}
    </CardsContainer>
  );
};
