import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Section, SliderButton, SliderButtonsContainer } from './styled';
import { NewSectionHeader } from '../NewSectionHeader';
import { DirectionEnum, NewSectionProps } from './types';
import useScreenSize from '../../hooks/useScreenSize';
import { cardMarginLeft } from '../ui/ItemCard/styled';

export const NewSection = ({
  id,
  title,
  url,
  urlTitle,
  rightBlock,
  children,
  headlineType,
  noHeader,
  asLink,
  cypress,
  hasHorizontalScroll = false
}: NewSectionProps) => {
  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  const { isSmallDesktop, isLarge } = useScreenSize();
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateScrollButtons = () => {
    const container = cardsContainerRef.current;

    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const containerScrollLeft = Math.round(container.scrollLeft);
      setIsLeftDisabled(containerScrollLeft <= 0);
      setIsRightDisabled(containerScrollLeft >= maxScrollLeft);
    }
  };

  const scrollContainer = (direction: DirectionEnum) => {
    const container = cardsContainerRef.current;

    if (container) {
      const scrollAmount = container.clientWidth + cardMarginLeft;
      container.scrollLeft +=
        direction === 'left' ? -scrollAmount : scrollAmount;
    }
  };

  useEffect(() => {
    updateScrollButtons();
    const container = cardsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', updateScrollButtons);
      return () => container.removeEventListener('scroll', updateScrollButtons);
    }
  }, []);

  const sliderButtons =
    hasHorizontalScroll && (isSmallDesktop || isLarge) ? (
      <SliderButtonsContainer>
        <SliderButton
          onClick={() => scrollContainer(DirectionEnum.left)}
          disabled={isLeftDisabled}
          direction={DirectionEnum.left}
          data-cy="slider-left-arrow-button"
        />
        <SliderButton
          onClick={() => scrollContainer(DirectionEnum.right)}
          disabled={isRightDisabled}
          direction={DirectionEnum.right}
          data-cy="slider-right-arrow-button"
        />
      </SliderButtonsContainer>
    ) : null;

  return (
    <Section id={id}>
      {!noHeader && (
        <NewSectionHeader
          title={title}
          url={url}
          asLink={asLink}
          urlTitle={urlTitle}
          rightBlock={sliderButtons || rightBlock}
          headlineType={headlineType}
          cypress={cypress}
          showBothRightBlocks={!!sliderButtons && hasHorizontalScroll}
        />
      )}
      {hasHorizontalScroll && React.isValidElement(children)
        ? React.cloneElement(children as ReactElement, {
            forwardedRef: cardsContainerRef
          })
        : children}
    </Section>
  );
};
