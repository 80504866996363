import styled from 'styled-components';
import { FlexibleDiv } from '../../styled/common';
import { SliderButtonProps } from './types';

export const Section = styled.section`
  padding: 0 0 15px;
`;

export const SliderButtonsContainer = styled(FlexibleDiv)`
  gap: 5px;
`;

export const SliderButton = styled.button<SliderButtonProps>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  background-color: transparent;
  background-image: url('/static/images/webview/icon-arrow-left.svg');
  background-size: 18px 10px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  transform: ${({ direction }) =>
    direction === 'left' ? 'none' : 'rotate(180deg)'};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  transition: opacity 0.2s ease;
`;
