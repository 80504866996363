import React, { ReactNode } from 'react';
import { LayoutContainer, MainContent, Banner } from './styled';

export const HomeLayoutWithBanner = ({ children }: { children: ReactNode }) => {
  return (
    <LayoutContainer>
      <Banner />
      <MainContent>{children}</MainContent>
      <Banner />
    </LayoutContainer>
  );
};
