import styled from 'styled-components';
import { Typography } from '../ui/Typography';
import { FlexibleDiv } from '../../styled/common';

export const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  margin: 0 0 15px;
  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const StyledLink = styled(Typography)`
  line-height: 20px;
  color: #2f1f19;
`;

export const Headline = styled(Typography)`
  margin: 0;
  line-height: 20px;
  font-size: 16px;
`;

export const PremiumTitle = styled.h1`
  font-size: 11px;
  font-weight: 400;
  color: #bcbcbc;
  margin: 0;
  text-align: right;
`;

export const HeaderRightBlock = styled(FlexibleDiv)`
  gap: 14px;
  align-items: center;
`;
